<template>
  <div id="defaultId1" class="company_detail">
    <div id="g2ede8" class="operations-content">

      <div class="operations-box pb20">
        <div class="operations-box-head mb10">
          <div class="operations-box-head operations-box-head-left" style="padding-top: 1.4285714285714286rem">
            <div class="operations-box-left">
              <div class="operations-content-head">
                <div class="perations-content-left">
                  <span :class="activeIEports == 'Imports'
                    ? 'perations-button perations-button-active'
                    : 'perations-button'
                    " @click="ceckIEports('Imports')">Imports</span>
                  <span @click="ceckIEports('Exports')" :class="activeIEports == 'Exports'
                    ? 'perations-button perations-button-active'
                    : 'perations-button'
                    ">Exports</span>
                </div>
                <!-- <el-dropdown @command="handleCommandone">
                  <span class="el-dropdown-link">
                    {{ selectcouny }}<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown" class="common_dropdown">
                    <el-dropdown-item command="Proportion of host country"> Proportion of host
                      country</el-dropdown-item>
                    <el-dropdown-item command="Continental Proportit"> Continental Proportit</el-dropdown-item>

                  </el-dropdown-menu>
                </el-dropdown> -->
              </div>
              <div>
                <el-table :data="tableData" style="width: 100%" :show-header="false" empty-text="No Data">
                  <el-table-column prop="Top" label="Top" :width="tableColumnWidth.colum1">
                    <template slot-scope="scope">
                      <span :class="scope.$index > 2
                        ? 'table-content'
                        : 'table-content '
                        ">{{ scope.$index + 1 }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="Country" label="Country" :width="tableColumnWidth.colum2">
                    <template slot-scope="scope">
                      <!-- @click="handleCommand('Proportion of host country', scope.row)" -->
                      <!-- table-content-color -->
                      <span :class="scope.$index > 2
                        ? 'table-content'
                        : 'table-content '
                        ">{{ scope.row.valueEn }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="Progress" label="" :width="tableColumnWidth.colum3">
                    <template slot-scope="scope">
                      <el-progress :text-inside="true" :stroke-width="22" define-back-color="#DDDDDD" :show-text="true"
                        :percentage="Number(scope.row.percentage.replace(/%/g, '').trim())"
                        :color="customColorMethod(scope.row.percentage, scope.$index)"></el-progress>
                    </template>
                  </el-table-column>
                  <el-table-column prop="percentage" label="percentage">
                    <template slot-scope="scope">
                      <span class="table-content">{{
                        scope.row.percentage
                        }}</span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
            <div class="operations-center-center">
              <div class="operations-center-select">
                <span class="bottom-titletwo detail_title_20 "> Trade Volume</span>
                <el-select ref="upSelect3" v-click-outside="clickOutSide3" popper-class="common_select" v-model="value"
                  placeholder="" @change="ChangesTrade">
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </div>
              <div class="echarts">
                <div ref="SupplyChainEchart" style="width: 100%; height: 35.857142857142854rem"></div>
              </div>
            </div>
          </div>
          <div class="operations-box-left operations-box-right">
            <div class="operations-content-head operations-right-head">
              <span style="font-size:1.42rem; color: #8497ab">Import Shipments</span>
              <span style="font-size:1.42rem; color: #1a2332">{{ sixColumns.importTransactions | FilterNum }}</span>
            </div>
            <div class="operations-content-head operations-right-head">
              <span style="font-size:1.42rem; color: #8497ab">Export Shipments</span>
              <span style="font-size:1.42rem; color: #1a2332">{{ sixColumns.exportTransactions | FilterNum }}</span>
            </div>
            <div class="operations-content-head operations-right-head">
              <span style="font-size:1.42rem; color: #8497ab">Import Countries</span>
              <span style="font-size:1.42rem; color: #1a2332">{{ sixColumns.importCountries }}</span>
            </div>
            <div class="operations-content-head operations-right-head">
              <span style="font-size:1.42rem; color: #8497ab">Export Countries</span>
              <span style="font-size:1.42rem; color: #1a2332">{{ sixColumns.exportCountries }}</span>
            </div>
            <div class="operations-content-head operations-right-head">
              <span style="font-size:1.42rem; color: #8497ab">Import Volume</span>
              <span style="font-size:1.42rem; color: #1a2332">{{ sixColumns.importValues | FilterNum }}</span>
            </div>
            <div class="operations-content-head operations-right-head">
              <span style="font-size:1.42rem; color: #8497ab">Export Volume</span>
              <span style="font-size:1.42rem; color: #1a2332">{{ sixColumns.exportValues | FilterNum }}</span>
            </div>
          </div>
        </div>
        <div :class="['mb10', powerArr[1]?.hasPrivilege ? '' : 'vaguesuo']"
          @click="powerArr[1]?.hasPrivilege ? '' : $bus.$emit('show-permission-dialog', '您没有权限访问该功能！')">
          <div :class="powerArr[1]?.hasPrivilege ? 'operations-box-center' : 'vague'">
            <h3 id="gf7b16" class="operations-center-head" style="margin-bottom:10px">
              <el-select ref="upSelect4" v-click-outside="clickOutSide4" popper-class="common_select" v-model="value2"
                placeholder="" style="width:400px" @change="centerEcharts">
                <el-option v-for="item in Centeroptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </h3>
            <div class="operations-box-head">
              <div class="operations-box-center-left">
                <div class="operations-box-center-headr operations-box-head">
                  Imports
                  <span class="callBack" v-if="ImpirtShow != 'Suppliers'" @click="callBack()">Back</span>

                </div>
                <h3 class="TopPorts">Top {{ ImpirtShow }}</h3>
                <h5 class="Byshipments">By Number of Shipments</h5>
                <div class="Byshipments-table" v-if="ImpirtShow == 'Suppliers'">
                  <div class="line_step" v-for="(item, index) in TopCustomers" :key="index">
                    <div class="txt flex_center_between_box">
                      <span class="line_title" @click="gotoProduct(item, 'Product')">{{ item.valueEn }}</span>
                      <span class="blue_num" style="color: #8497AB;">{{ item.dateCount | FilterNum }}</span>
                    </div>
                    <el-progress color="#1290C9" :stroke-width="10" :show-text="false" define-back-color="#DDDDDD"
                      :percentage="getProgress(item)"></el-progress>
                  </div>
                </div>
                <div class="Byshipments-table" v-if="ImpirtShow == 'Product'">
                  <div class="line_step" v-for="(item, index) in TopPortsImportProduct" :key="index">
                    <div class="txt flex_center_between_box">
                      <span class="line_title" @click="gotoProductPorts(item.code, 'Ports')">{{ item.valueEn }}</span>
                      <span class="blue_num" style="color: #8497AB;">{{ item.dateCount | FilterNum }}</span>
                    </div>
                    <el-progress color="#1290C9" :stroke-width="10" :show-text="false" define-back-color="#DDDDDD"
                      :percentage="getProgress(item)"></el-progress>
                  </div>
                </div>
                <div class="Byshipments-table" v-if="ImpirtShow == 'Ports'">
                  <div class="line_step" v-for="(item, index) in TopPortsImportPorts" :key="index">
                    <div class="txt flex_center_between_box">
                      <span class="line_title" style="text-decoration-line:none">{{ item.valueEn }}</span>
                      <span class="blue_num" style="color: #8497AB;">{{ item.dateCount | FilterNum }}</span>
                    </div>
                    <el-progress color="#1290C9" :stroke-width="10" :show-text="false" define-back-color="#DDDDDD"
                      :percentage="getProgress(item)"></el-progress>
                  </div>
                </div>
                <div class="flex_center_between_box">
                  <el-select ref="upSelect5" v-click-outside="clickOutSide5" popper-class="common_select" v-model="imporetvalue" placeholder="" style="width:360px"
                    @change="Centeroptionschange(1)">
                    <el-option v-for="item in TopCustomers" :key="item.valueEn" :label="item.valueEn"
                      :value="item.code">
                    </el-option>
                  </el-select>
                </div>
                <div class="echartsimport">
                  <div ref="ImportEchart" style="width: 100%; height: 422px"></div>
                </div>
              </div>
              <div class="operations-box-center-left operations-box-center-right">
                <div class="operations-box-center-headr operations-box-head">
                  Exports
                  <span class="callBack" v-if="ExportShow != 'Buyers'" @click="callBacktwo()">Back</span>

                </div>
                <h3 class="TopPorts">Top {{ ExportShow }}</h3>
                <h5 class="Byshipments">By Number of Shipments</h5>
                <div class="Byshipments-table" v-if="ExportShow == 'Buyers'">
                  <div class="line_step" v-for="(item, index) in TopSuppliers" :key="index">
                    <div class="txt flex_center_between_box">
                      <span class="line_title" @click="gotoProducttwo(item, 'Product')">{{ item.valueEn }}</span>
                      <span class="blue_num" style="color: #8497AB;">{{ item.dateCount | FilterNum }}</span>
                    </div>
                    <el-progress color="#1290C9" :stroke-width="10" :show-text="false" define-back-color="#DDDDDD"
                      :percentage="getProgress(item)"></el-progress>
                  </div>
                </div>
                <div class="Byshipments-table" v-if="ExportShow == 'Product'">
                  <div class="line_step" v-for="(item, index) in TopPortsExportProduct" :key="index">
                    <div class="txt flex_center_between_box">
                      <span class="line_title" @click="gotoProductPortstwo(item.code, 'Ports')">{{ item.valueEn
                      }}</span>
                      <span class="blue_num" style="color: #8497AB;">{{ item.dateCount | FilterNum }}</span>
                    </div>
                    <el-progress color="#1290C9" :stroke-width="10" :show-text="false" define-back-color="#DDDDDD"
                      :percentage="getProgress(item)"></el-progress>
                  </div>
                </div>
                <div class="Byshipments-table" v-if="ExportShow == 'Ports'">
                  <div class="line_step" v-for="(item, index) in TopPortsExportPorts" :key="index">
                    <div class="txt flex_center_between_box">
                      <span class="line_title" style="text-decoration-line:none">{{ item.valueEn }}</span>
                      <span class="blue_num" style="color: #8497AB;">{{ item.dateCount | FilterNum }}</span>
                    </div>
                    <el-progress color="#1290C9" :stroke-width="10" :show-text="false" define-back-color="#DDDDDD"
                      :percentage="getProgress(item)"></el-progress>
                  </div>
                </div>
                <div class="flex_center_between_box">
                  <el-select ref="upSelect2" v-click-outside="clickOutSide2" popper-class="common_select"
                    v-model="exportvalue" placeholder="" style="width:360px" @change="Centeroptionschange(2)">
                    <el-option v-for="item in TopSuppliers" :key="item.value" :label="item.valueEn" :value="item.code">
                    </el-option>
                  </el-select>
                </div>
                <div class="echartsimport">
                  <div ref="ExportEchart" style="width: 100%; height: 422px"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="operations-box-center" v-if="ImporttableData?.length > 0">
          <div class=" operations-box-bottom">
            <h3 id="gf7b16" style="margin-bottom:20px" class="operations-box-head">
              <span class="bottom-title">Imports & Exports Overview</span>
              <el-select ref="upSelect1" v-click-outside="clickOutSide1" popper-class="common_select"
                v-model="importsandexports" placeholder="" style="width:400px" @change="getImportExportTransactionList">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </h3>
            <el-table
              :data="ImporttableData.filter(data => !SearchHscode || data.hsCode.toLowerCase().includes(SearchHscode.toLowerCase()))"
              style="width: 100%" :key="key" empty-text="No Data">
              <el-table-column prop="Date" width="120" label="Date" show-overflow-tooltip align="center">
                <template slot-scope="scope">
                  <span class="table-content">{{ scope.row.date }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="products" label="Product" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span class="table-content">{{ scope.row.products }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="hsCode" width="220" label="Hscode" align="center" show-overflow-tooltip>
                <!-- 表头切换 slot="header" slot-scope="scope"-->
                <template slot="header" slot-scope="scope">
                  <span class="table_add" @click="showSearchHscode()">
                    <el-input autocomplete="off" v-model="SearchHscode" type="text" v-if="isshowSearchHscode"
                      suffix-icon="el-icon-search" placeholder="Search HS Code" @change="searchPersonByName(scope)"
                      @blur="seachHS" />
                    <span v-else>
                      <span class="font_bold">HS Code</span>
                      <svg t="1718683815723" class="icon ml5" @click="searchPersonByName()" viewBox="0 0 1024 1024"
                        version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7262" width="2.14rem" height="2.14rem">
                        <path
                          d="M863.38 825.09L714.17 675.88c48.01-57.12 74.18-128.58 74.18-204.08 0-84.89-33.06-164.7-93.08-224.72C635.24 187.05 555.44 154 470.55 154c-84.89 0-164.7 33.06-224.72 93.08s-93.08 139.83-93.08 224.72 33.06 164.7 93.08 224.72 139.83 93.08 224.72 93.08c76.22 0 148.33-26.67 205.72-75.56l149.08 149.08a26.805 26.805 0 0 0 19.01 7.88c6.88 0 13.76-2.63 19.01-7.88a26.805 26.805 0 0 0 7.88-19.01c0-6.89-2.62-13.77-7.87-19.02zM206.53 471.8c0-145.58 118.44-264.02 264.02-264.02 145.58 0 264.02 118.44 264.02 264.02S616.13 735.82 470.55 735.82c-145.58 0-264.02-118.44-264.02-264.02z"
                          fill="#1290C9" p-id="7263"></path>
                      </svg>

                    </span>
                  </span>
                </template>
                <template slot-scope="scope">
                  <span class="table-content">{{ scope.row.hsCode }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="BuyerNames" label="Buyer Name" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span class="table-content company_link" @click="setid(scope.row, 'Buyer')">{{ scope.row.buyerEn
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="exportCountryEn" label="Destination" width="160" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span class="table-content">{{ scope.row.importCountryEn }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="suppliersEn" label="Supplier Name" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span class="table-content company_link" @click="setid(scope.row, 'Supplier')">{{
                    scope.row.suppliersEn
                    }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="Origin" label="Origin" width="150" show-overflow-tooltip align="center">
                <template slot-scope="scope">
                  <span class="table-content">{{ scope.row.exportCountryEn }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="Amount" label="Amount" width="100" align="center">
                <template slot-scope="scope">
                  <span class="table-content">{{ scope.row.amount | FilterNum }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="Details" label="Details" align="center" width="90">
                <template slot-scope="scope">
                  <svg t="1718681277467" class="icon" @click="gotoExportDetail(scope.row.dataId)"
                    viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6986" width="32"
                    height="32">
                    <path
                      d="M867.2 995.7H159.8c-51.7 0-93.8-42.1-93.8-93.8V139.6c0-51.7 42.1-93.8 93.8-93.8h707.3c51.7 0 93.8 42.1 93.8 93.8v762.3c0.1 51.8-42 93.8-93.7 93.8zM159.8 85.8c-29.7 0-53.8 24.1-53.8 53.8v762.3c0 29.7 24.1 53.8 53.8 53.8h707.3c29.7 0 53.8-24.1 53.8-53.8V139.6c0-29.7-24.1-53.8-53.8-53.8H159.8z"
                      fill="#1290C9" p-id="6987"></path>
                    <path
                      d="M753.2 381.2H273.8c-11 0-20-9-20-20s9-20 20-20h479.3c11 0 20 9 20 20s-8.9 20-19.9 20zM521.9 540.8H273.8c-11 0-20-9-20-20s9-20 20-20h248.1c11 0 20 9 20 20s-8.9 20-20 20zM644.9 700.4H273.8c-11 0-20-9-20-20s9-20 20-20h371.1c11 0 20 9 20 20s-8.9 20-20 20z"
                      fill="#1290C9" p-id="6988"></path>
                  </svg>
                  <!-- <span class="table-content">{{ scope.row.Details }}</span> -->
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <!-- <PermissionDialog></PermissionDialog> -->
      <ReLogin :reLoginPop="reLoginVis"></ReLogin>
    </div>
  </div>
</template>
<script>
import {
  getImportorExportTransaction,
  getImportExportALl,
  getImportorExportRankBySC,
  getImportOrExportProductTrade,
  showImportorExportForSCRanks,
  getImportOrExportTradeByContinent,
  getPartPrivilege

} from "@/api/companyApi";
import PermissionDialog from '@/components/power/PermissionDialog.vue';
import ReLogin from "../../../../components/ReLogin.vue";

export default {
  name: "",
  props: {},

  components: {
    PermissionDialog,
    ReLogin,
  },
  created() {
    let screenWidth = window.innerWidth//获取屏幕宽度
    if (screenWidth <= 1441) {
      this.tableColumnWidth = { // 表格不支持rem单位，这块只能手动转rem为px
        colum1: this.tableColumnWidth.colum1 / 1.5,
        colum2: this.tableColumnWidth.colum2 / 1.5,
        colum3: this.tableColumnWidth.colum3 / 1.5,
        colum4: this.tableColumnWidth.colum4 / 1.5,
        colum5: this.tableColumnWidth.colum5 / 1.5,
        colum6: this.tableColumnWidth.colum6 / 1.5,
      }
    }
  },
  data() {
    return {
      tableColumnWidth: {
        colum1: 60,
        colum2: 220,
        colum3: 220,
        colum4: 220,
        colum5: 220,
        colum6: 220,
      },
      reLoginVis: false,
      powerArr: [],
      importsandexports: '1',
      ImpirtShow: 'Suppliers',
      ExportShow: 'Buyers',
      activeIEports: "Imports",
      sixColumns: {

      },
      selectcouny: "Proportion of host country",
      ImporttableData: [


      ],

      tableData: [],
      key: 0,
      topImportContinentList: [],
      topImportCountriesList: [],
      topExportContinentList: [],
      topExportCountriesList: [],
      importCountryTrendMap: [],
      exportCountryTrendMap: [],
      options: [
        {
          value: "1",
          label: "Imports",
        },
        {
          value: "2",
          label: "Exports",
        },
      ],
      Centeroptions: [
        {
          value: "Shipments",
          label: "Shipments",
        },
        {
          value: "value",
          label: "value",
        },
      ],
      TopCustomers: [],
      TopProductCustomers: [],
      TopPortsImportProduct: [],
      TopPortsExportProduct: [],
      TopPortsImportPorts: [],
      TopPortsExportPorts: [],
      TopSuppliers: [],
      value: "Imports",
      value2: "Shipments",
      isshowSearchHscode: false,
      SearchHscode: "",
      page: {
        pageNo: 1,
        pageSize: 5,
        total: 0,
      },
      supplierId: '',
      imporetvalue: "",
      exportvalue: '',
      importTrendListEcharts: [],
      importGrowthRateTrendList: [],
      exportGrowthRateTrendList: [],
      exportTrendListEcharts: [],
      echartsSever: [],
      echartsSeveryear: [],
      importTrendMap: [],
      exportTrendMap: [],
      imporetoption: [{
        value: "Ford India Pvt. Ltd.",
        label: "Ford India Pvt. Ltd.",
      },
      {
        value: "Apple Inc",
        label: "Apple Inc",
      },]


    };


  },
  methods: {
    clickOutSide1() {

      if (this.$refs.upSelect1) {
        this.$refs.upSelect1.visible = false

      }

    },
    clickOutSide2() {

      if (this.$refs.upSelect2) {
        this.$refs.upSelect2.visible = false

      }

    },
    clickOutSide3() {

      if (this.$refs.upSelect3) {
        this.$refs.upSelect3.visible = false

      }

    },
    clickOutSide5() {

if (this.$refs.upSelect5) {
  this.$refs.upSelect5.visible = false

}

},
    clickOutSide4() {

      if (this.$refs.upSelect4) {
        this.$refs.upSelect4.visible = false

      }

    },
    getPartPrivilege() {
      getPartPrivilege({
        moduleId: this.$route.query.idpower
      }).then(res => {
        this.powerArr[0] = (res.data.filter(item => item.id == '431'))[0]
        this.powerArr[1] = (res.data.filter(item => item.id == '432'))[0]
        this.powerArr[2] = (res.data.filter(item => item.id == '432'))[0]
        this.powerArr[3] = (res.data.filter(item => item.id == '434'))[0]
      })

    },
    // 上方统计图切换
    handleCommandone(command) {

      this.selectcouny = command
      this.ceckIEports(this.activeIEports)
      // if (command == 'Proportion of host country') {
      //   if (this.activeIEports == 'Imports') {
      //     // this.ceckIEports(this.activeIEports)
      //     this.tableData = this.topImportCountriesList


      //   } else {
      //     this.tableData = this.topExportCountriesList
      //   }

      // } else {
      //   if (this.activeIEports == 'Imports') {
      //     this.tableData = this.topImportContinentList


      //   } else {
      //     this.tableData = this.topExportContinentList
      //   }
      // }
    },
    // 切换洲和国家
    handleCommand(command, scope) {

      this.selectcouny = command
      if (command == 'Proportion of host country') {
        if (this.activeIEports == 'Imports') {

          this.tableData = this.topImportCountriesList
          getImportOrExportTradeByContinent({
            aaaId: this.$route.query.id3a,
            type: 1,
            market: scope.code
          }).then(res => {
            this.tableData = res.data.topImportCountriesList
          })

        } else {
          this.tableData = this.topExportCountriesList

        }

      } else {
        if (this.activeIEports == 'Imports') {
          this.tableData = this.topImportCountriesList
        } else {
          this.tableData = this.topExportCountriesList
        }
      }
    },
    centerEcharts() {
      showImportorExportForSCRanks({
        dimension: this.value2,
        aaaId: this.$route.query.id3a
      }).then(res => {
        this.TopCustomers = res.data.importSupplierList
        this.imporetvalue = res.data.importSupplierList[0].code
        this.TopSuppliers = res.data.exportSupplierList
        this.exportvalue = res.data.exportSupplierList[0].code
        this.importTrendListEcharts = res.data.importTrendList
        this.importGrowthRateTrendList = res.data.importGrowthRateTrendList
        this.exportTrendListEcharts = res.data.exportTrendList
        this.exportGrowthRateTrendList = res.data.exportGrowthRateTrendList
      })
    
    },
    ChangesTrade() {
      if (this.value == 1) {
        this.ceckIEports('Imports')
      } else {
        this.ceckIEports('Exports')
      }
    },
    // 柱状图更新select
    Centeroptionschange(value) {

      getImportorExportRankBySC({
        aaaId: this.$route.query.id3a,
        dimension: this.value2,
        type: value,
        supplierId: value == 1 ? this.imporetvalue : '',
        buyerId: value == 2 ? this.exportvalue : ''
      }).then(res => {
        if (res.code == 200) {
          if (value == 1) {
            this.importTrendListEcharts = res.data.importTrendList
            this.importGrowthRateTrendList = res.data.importGrowthRateTrendList

          } else {
            this.exportTrendListEcharts = res.data.exportTrendList
            this.exportGrowthRateTrendList = res.data.exportGrowthRateTrendList
          }
          let that = this
          this.$nextTick(() => {
            // 在 DOM 更新后执行代码
            that.ImportExportEcharttwo();
          });
        }

      })
    },
    // 获取所有数据
    getImportExportALl() {
      getImportExportALl({
        id3a: this.$route.query.id3a,
        companyName: '',

      }).then(res => {
        if (res.code == 200) {
          this.sixColumns = res.data.sixColumns
          this.TopCustomers = res.data.importSupplierList
          this.imporetvalue = res.data.importSupplierList[0]?.code
          this.TopSuppliers = res.data.exportSupplierList
          this.exportvalue = res.data.exportSupplierList[0]?.code
          this.tableData = res.data.topImportCountriesList

          this.topExportContinentList = res.data.topExportCountriesList
          this.topExportCountriesList = res.data.topExportCountriesList
          this.topImportContinentList = res.data.topImportCountriesList
          this.topImportCountriesList = res.data.topImportCountriesList
          this.importGrowthRateTrendList = res.data.importGrowthRateTrendList == null ? [] : res.data.importGrowthRateTrendList
          this.exportGrowthRateTrendList = res.data.exportGrowthRateTrendList == null ? [] : res.data.exportGrowthRateTrendList
          this.importTrendListEcharts = res.data.importTrendList == null ? [] : res.data.importTrendList
          this.exportTrendListEcharts = res.data.exportTrendList == null ? [] : res.data.exportTrendList
          // let exportTrendMap = 
          this.exportTrendMap = res.data.exportTrendMap
          this.importTrendMap = res.data.importTrendMap
          this.importCountryTrendMap = res.data.importCountryTrendMap
          this.exportCountryTrendMap = res.data.exportCountryTrendMap
          this.changezhexianEchart(res.data.importCountryTrendMap)

          this.$emit("UpdatamarkTotalList", res.data.nineSquareGrid);

          // const transformedData = Object.entries(exportTrendMap).flatMap(([countryCode, entries]) =>
          //   entries.map(entry => ({
          //     ...entry,
          //     country: `${countryCode}`
          //   }))
          // );
          // const uniqueArray = []
          // transformedData.forEach(value => {
          //   if (!uniqueArray.includes(value.country)) {
          //     uniqueArray.push(value.country);
          //   }
          // });
          let that = this

          this.$nextTick(() => {

            // 在 DOM 更新后执行代码
            // that.ImportExportEchart();
            that.ImportExportEcharttwo();
          });
        }

      })

    },
    ceckIEports(row) {
      this.activeIEports = row;
      if (row == "Imports") {
        this.value = '1'
        if (this.selectcouny == 'Proportion of host country') {
          if (this.importCountryTrendMap) {
            this.changezhexianEchart(this.importCountryTrendMap)
          }
          this.tableData = this.topImportCountriesList
        } else {
          if (this.importTrendMap) {
            this.changezhexianEchart(this.importTrendMap)
          }
          this.tableData = this.topImportContinentList
        }
      } else {
        this.value = '2'
        if (this.selectcouny == 'Proportion of host country') {
          if (this.exportCountryTrendMap) {
            this.changezhexianEchart(this.exportCountryTrendMap)
          }
          this.tableData = this.topExportCountriesList
        } else {
          if (this.exportTrendMap) {
            this.changezhexianEchart(this.exportTrendMap)
          }
          this.tableData = this.topExportContinentList
        }
      }
    },
    // 数据修改切换import export
    changezhexianEchart(exportTrendMap) {
      let entries = Object.entries(exportTrendMap);
      let arrlist = []
      this.echartsSeveryear = []
      entries[0][1].map(item => {
        this.echartsSeveryear.push(item.code)
      })
      // echartsSeveryear
      entries.map(item => {
        arrlist.push({
          name: item[0],
          data: item[1].map(item => (item.dateCount)),
          type: "line",
          stack: "Total",
          areaStyle: {},
          emphasis: {
            focus: "series",
          },
          symbol: '', // 将点的样式设置为空字符串
          showSymbol: false, // 隐藏折线上的点，
          smooth: 0.5,
        })
      })
      arrlist.map((item, index) => {
        if (index == 0) {
          item.areaStyle = {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0,
                color: 'rgba(18, 144, 201, 1)' // 起始颜色，这里使用蓝色
              },

              {
                offset: 0.5,
                color: 'rgba(0, 0, 255, 0.3)' // 结束颜色，这里使用透明色
              },
              {
                offset: 1,
                color: 'rgba(0, 0, 255, 0)' // 结束颜色，这里使用透明色
              }]
            }
          }
        }
        if (index == 1) {
          item.areaStyle = {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0,
                color: 'rgba(129, 201, 122, 1)' // 结束颜色，这里使用透明色
              },

              {
                offset: 0.5,
                color: 'rgba(129, 201, 122, 0.5)' // 结束颜色，这里使用透明色
              },
              {
                offset: 1,

                color: 'rgba(129, 201, 122, 0)' // 结束颜色，这里使用透明色
              }]
            }
          }
        }
        if (index == 2) {
          item.areaStyle = {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0,
                color: 'rgba(255, 118, 0, 1)' // 结束颜色，这里使用透明色
              },

              {
                offset: 0.5,
                color: 'rgba(255, 118, 0, 0.5)' // 结束颜色，这里使用透明色
              },
              {
                offset: 1,

                color: 'rgb(255, 118, 0,0.2)' // 结束颜色，这里使用透明色
              }
              ]
            }
          }
        }
        if (index == 3) {
          item.areaStyle = {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0,
                color: 'rgba(242, 131, 191, 1)' // 结束颜色，这里使用透明色
              },

              {
                offset: 0.5,
                color: 'rgba(242, 131, 191, 0.5)' // 结束颜色，这里使用透明色
              },
              {
                offset: 1,

                color: 'rgba(142, 134, 221, 0.2)' // 结束颜色，这里使用透明色
              }
              ]
            }
          }
        }
        if (index == 4) {
          item.areaStyle = {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0,
                color: 'rgba(18, 144, 201, 1)' // 结束颜色，这里使用透明色
              },

              {
                offset: 0.5,
                color: 'rgba(18, 144, 201, 0.5)' // 结束颜色，这里使用透明色
              },
              {
                offset: 1,

                color: 'rgba(18, 144, 201, 0.2)' // 结束颜色，这里使用透明色
              }
              ]
            }
          }
        }

      })
      this.echartsSever = arrlist
      let that = this
      this.$nextTick(() => {

        // 在 DOM 更新后执行代码
        that.ImportExportEchart(arrlist);
      });
    },
    // 上方趋势图
    ImportExportEchart(arrlist) {


      let that = this;
      this.echartsBox = this.$echarts.init(this.$refs.SupplyChainEchart);
      const option = {
        // title: {
        //   text: "Stacked Area Chart",
        // },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        // legend: {
        //   data: ["Email", "Union Ads", "Video Ads", "Direct", "Search Engine"],
        // },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {},
        //   },
        // },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            axisLine: {
              show: false // 不显示 x 轴线
            },
            axisTick: {
              show: false // 不显示 x 轴上的间隔点
            },
            data: that.echartsSeveryear,
          },

        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: arrlist,
        // series: [
        //   {
        //     name: "EU",
        //     type: "line",
        //     stack: "Total",
        //     areaStyle: {},
        //     emphasis: {
        //       focus: "series",
        //     },
        //     symbol: '', // 将点的样式设置为空字符串
        //     showSymbol: false, // 隐藏折线上的点，
        //     smooth: 0.5,
        //     data: [0, 0,0, 101,21, 134,22],
        //     areaStyle: {
        //       color: {
        //         type: 'linear',
        //         x: 0,
        //         y: 0,
        //         x2: 0,
        //         y2: 1,
        //         colorStops: [{
        //           offset: 0,
        //           color: 'rgba(0, 0, 255, 0.7)' // 起始颜色，这里使用蓝色
        //         },

        //         {
        //           offset: 0.5,
        //           color: 'rgba(0, 0, 255, 0.3)' // 结束颜色，这里使用透明色
        //         },
        //         {
        //           offset: 1,
        //           color: 'rgba(0, 0, 255, 0)' // 结束颜色，这里使用透明色
        //         }]
        //       }
        //     },

        //   },
        //   {
        //     name: "Union Ads",
        //     type: "line",
        //     stack: "Total",
        //     areaStyle: {},
        //     smooth: 0.5,
        //     emphasis: {
        //       focus: "series",
        //     },
        //     symbol: '', // 将点的样式设置为空字符串
        //     showSymbol: false, // 隐藏折线上的点，
        //     data: [20, 182, 191, 234, 290, 330, 310],
        //     areaStyle: {
        //       color: {
        //         type: 'linear',
        //         x: 0,
        //         y: 0,
        //         x2: 0,
        //         y2: 1,
        //         colorStops: [{
        //           offset: 0,
        //           color: 'rgba(129, 201, 122, 1)' // 结束颜色，这里使用透明色
        //         },

        //         {
        //           offset: 0.5,
        //           color: 'rgba(129, 201, 122, 0.5)' // 结束颜色，这里使用透明色
        //         },
        //         {
        //           offset: 1,

        //           color: 'rgba(129, 201, 122, 0)' // 结束颜色，这里使用透明色
        //         }]
        //       }
        //     },
        //   },
        //   {
        //     name: "Video Ads",
        //     type: "line",
        //     stack: "Total",
        //     areaStyle: {},
        //     smooth: 0.5,
        //     emphasis: {
        //       focus: "series",
        //     },
        //     symbol: '', // 将点的样式设置为空字符串
        //     showSymbol: false, // 隐藏折线上的点，
        //     data: [150, 232, 201, 154, 190, 330, 410],
        //     areaStyle: {
        //       color: {
        //         type: 'linear',
        //         x: 0,
        //         y: 0,
        //         x2: 0,
        //         y2: 1,
        //         colorStops: [{
        //           offset: 0,
        //           color: 'rgb(250, 199, 88,1)' // 结束颜色，这里使用透明色
        //         },

        //         {
        //           offset: 0.5,
        //           color: 'rgb(250, 199, 88,0.5)' // 结束颜色，这里使用透明色
        //         },
        //         {
        //           offset: 1,

        //           color: 'rgb(250, 199, 88,0.2)' // 结束颜色，这里使用透明色
        //         }]
        //       }
        //     },
        //   },
        //   {
        //     name: "Direct",
        //     type: "line",
        //     stack: "Total",
        //     areaStyle: {},
        //     smooth: 0.5,
        //     symbol: '', // 将点的样式设置为空字符串
        //     showSymbol: false, // 隐藏折线上的点，
        //     emphasis: {
        //       focus: "series",
        //     },
        //     data: [320, 332, 301, 334, 390, 330, 320],
        //     areaStyle: {
        //       color: {
        //         type: 'linear',
        //         x: 0,
        //         y: 0,
        //         x2: 0,
        //         y2: 1,
        //         colorStops: [{
        //           offset: 0,
        //           color: 'rgba(237, 134, 155, 1)' // 结束颜色，这里使用透明色
        //         },

        //         {
        //           offset: 0.5,
        //           color: 'rgba(237, 134, 155, 0.5)' // 结束颜色，这里使用透明色
        //         },
        //         {
        //           offset: 1,

        //           color: 'rgba(237, 134, 155, 0.2)' // 结束颜色，这里使用透明色
        //         }]
        //       }
        //     },
        //   },
        //   {
        //     name: "Search Engine",
        //     type: "line",
        //     stack: "Total",
        //     label: {
        //       show: true,
        //       position: "top",
        //     },
        //     symbol: '', // 将点的样式设置为空字符串
        //     showSymbol: false, // 隐藏折线上的点，
        //     areaStyle: {},
        //     smooth: 0.5,
        //     emphasis: {
        //       focus: "series",
        //     },
        //     data: [820, 932, 901, 934, 1290, 1330, 1320],

        //     areaStyle: {
        //       color: {
        //         type: 'linear',
        //         x: 0,
        //         y: 0,
        //         x2: 0,
        //         y2: 1,
        //         colorStops: [{
        //           offset: 0,
        //           color: 'rgba(145, 203, 230, 1)' // 结束颜色，这里使用透明色
        //         },

        //         {
        //           offset: 0.5,
        //           color: 'rgba(145, 203, 230, 0.5)' // 结束颜色，这里使用透明色
        //         },
        //         {
        //           offset: 1,

        //           color: 'rgba(145, 203, 230, 0.2)' // 结束颜色，这里使用透明色
        //         }]
        //       }
        //     },
        //   },
        //   {
        //     name: "Imports Weight",
        //     type: "scatter",
        //     symbol: 'circle', // 设置数据点的样式为圆形
        //     symbolSize: 10, // 设置数据点的大小为 10
        //     itemStyle: {
        //       color: 'black' // 设置数据点的颜色为红色
        //     },
        //     data: [
        //       [2, 2000] // 设置数据点的位置为 x 轴上的第 2 个刻度和 y 轴上的 200
        //     ]
        //   }
        // ],
      };


      this.echartsBox.setOption(option, true);

    },
    // 获取Overview of imports and exports数据
    getImportExportTransactionList() {
      getImportorExportTransaction({
        id3a: this.$route.query.id3a,
        type: this.importsandexports,
        countryCode: this.$route.query.companyCountry,
        page: 1,
        pageSize: 20,
      }).then(res => {
        if (res.code == 200) {
          this.ImporttableData = res.data.rows

        } else {
          this.ImporttableData = []
        }
      }).catch(err => {
        this.reLoginVis = String(err).includes('403')
      })
    },
    // 进度条百分比
    getProgress(item) {
      try {
        let tempData = (Number(item.dateCount) / Number(item.totalCount)) * 100;
        if (tempData > 0 && tempData <= 100) {
          return tempData;
        } else {
          return 0;
        }
      } catch (error) {

        return 0;
      }
    },
    // hscode查询
    searchPersonByName() {
      this.isshowSearchHscode = true
      this.key++
    },
    // 去贸易关单详情
    gotoExportDetail(Details) {
      if (this.powerArr[3]?.hasPrivilege) {
        this.$router.push({ path: 'ImportDetail', query: { companyName: this.$route.query.companyName, idpower: this.$route.query.idpower, id3a: this.$route.query.id3a, companyCountry: this.$route.query.companyCountry, DetailsiD: Details } });

      } else {
        this.$bus.$emit('show-permission-dialog', '您没有权限访问该功能！');
      }

    },
    showSearchHscode(row) {

    },
    seachHS() {
      if (!this.SearchHscode.length > 0) {
        this.isshowSearchHscode = false
      }
      this.key++

    },
    // 点击获取铲平
    gotoProduct(companyName, showarr) {
      this.supplierId = companyName.code
      getImportOrExportProductTrade({
        aaaId: this.$route.query.id3a,
        supplierId: companyName.code,
        type: '1',
        dimension: 'Shipments'
      }).then(res => {
        if (res.code == 200) {
          if (res.code == 200) {
            this.TopPortsImportProduct = res.data.items
          }
        }
      })
      this.ImpirtShow = showarr
    },
    // 点击获取port
    gotoProductPorts(companyName, showarr) {
      getImportOrExportProductTrade({
        aaaId: this.$route.query.id3a,
        supplierId: this.supplierId,
        hscode: companyName,
        type: '1',
        dimension: 'Shipments'
      }).then(res => {
        if (res.code == 200) {
          this.TopPortsImportPorts = res.data.items
          // this.TopPortsImportProduct=res.data.items
        }
      })
      this.ImpirtShow = showarr
    },
    // export点击获取铲平
    gotoProducttwo(companyName, showarr) {
      this.supplierId = companyName.code
      getImportOrExportProductTrade({
        aaaId: this.$route.query.id3a,
        supplierId: companyName.code,
        type: '2',
        dimension: 'Shipments'
      }).then(res => {
        if (res.code == 200) {
          this.TopPortsExportProduct = res.data.items
        }
      })
      this.ExportShow = showarr
    },
    // export点击获取点击获取port
    gotoProductPortstwo(companyName, showarr) {
      getImportOrExportProductTrade({
        aaaId: this.$route.query.id3a,
        supplierId: this.supplierId,
        hscode: companyName,
        type: '2',
        dimension: 'Shipments'
      }).then(res => {
        if (res.code == 200) {
          this.TopPortsExportPorts = res.data.items
        }
      })
      this.ExportShow = showarr
    },
    callBack() {

      if (this.ImpirtShow == 'Product') {
        this.ImpirtShow = 'Suppliers'
      } else if (this.ImpirtShow == 'Ports') {
        this.ImpirtShow = 'Product'
      }


    },
    callBacktwo() {
      if (this.ExportShow == 'Product') {
        this.ExportShow = 'Buyers'
      } else if (this.ExportShow == 'Ports') {
        this.ExportShow = 'Product'
      }
    },
    // 最上方百分比展示
    customColorMethod(percentage1, index) {
      let percentage = percentage1.replace(/%/g, '')
      if (index == 0) {
        return "#1290C9";
      } else if (index == 1) {
        return "#81C97A";
      } else if (index == 2) {
        return "#FF7600";
      } else if (index == 3) {
        return "#F283BF";
      } else if (index == 4) {
        return "#8E86DD";
      }
    },

    // 柱状图
    ImportExportEcharttwo() {
      let that = this;
      this.importsBox1 = this.$echarts.init(this.$refs.ImportEchart);
      this.importsBox2 = this.$echarts.init(this.$refs.ExportEchart);

     

      const option1 = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',

          }
        },
        grid: {
          left: '0%', // 设置左侧边距
          right: '0%',
          top: '12%',
          bottom: '0%',
          containLabel: true
        },
        legend: {
          right: '10px',
          top: '0px',
          //  width: '200px'
          // 其他图例配置项
          // ...
        },
        xAxis: {
          type: 'category',
          data: that.importTrendListEcharts.map(item => item.value),
          axisTick: {
            show: false // 不显示 x 轴上的间隔点
          }
        },
        yAxis: [
          {
            type: 'value',



            axisLabel: {
              formatter: '{value} '
            }
          },
          {
            type: 'value',
            position: 'right',
            alignTicks: true,
            min: -100,
            max: 100,
            interval: 20,
            axisLabel: {
              formatter: '{value} %'
            }

          }
        ],
        series: [
          {
            name: 'Import Quantity',
            type: 'bar',
            barWidth: '20',
            itemStyle: { color: '#1290C9' },
            emphasis: {
              itemStyle: {
                color: '#1290C9',
              },
            },
            data: that.importTrendListEcharts.map(item => item.dateCount)
          },
          {
            name: 'Increase/Decrease Rate',
            type: 'line',
            barWidth: '20',
            yAxisIndex: 1,
            itemStyle: { color: '#FF7600' },
            emphasis: {
              itemStyle: {
                color: '#FF7600',
              },
            },
            tooltip: {
              valueFormatter: function (value) {
                return value + ' %';
              }
            },
            data: that.importGrowthRateTrendList.map(item => item.value)

          }
        ]
      };
      const option2 = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',

          }
        },
        grid: {
          left: '0%', // 设置左侧边距
          right: '0%',
          top: '12%',
          bottom: '0%',
          containLabel: true
        },
        legend: {
          right: '10px',
          top: '0px',
          //  width: '200px'
          // 其他图例配置项
          // ...
        },
        xAxis: {
          type: 'category',
          data: that.exportTrendListEcharts.map(item => item.value),
          axisTick: {
            show: false // 不显示 x 轴上的间隔点
          }
        },
        yAxis: [
          {
            type: 'value',



            axisLabel: {
              formatter: '{value} '
            }
          },
          {
            type: 'value',
            position: 'right',
            alignTicks: true,
            min: -100,
            max: 100,
            interval: 20,
            axisLabel: {
              formatter: '{value} %'
            }

          }
        ],
        series: [
          {
            name: 'Export Quantity',
            type: 'bar',
            barWidth: '20',
            itemStyle: { color: '#1290C9' },
            emphasis: {
              itemStyle: {
                color: '#1290C9',
              },
            },
            data: that.exportTrendListEcharts.map(item => item.dateCount)
          },
          {
            name: 'Increase/Decrease Rate',
            type: 'line',
            barWidth: '20',
            yAxisIndex: 1,
            itemStyle: { color: '#FF7600' },
            emphasis: {
              itemStyle: {
                color: '#FF7600',
              },
            },
            tooltip: {
              valueFormatter: function (value) {
                return value + ' %';
              }
            },
            data: that.exportGrowthRateTrendList.map(item => item.value)

          }
        ]
      };
      this.importsBox1.setOption(option1);
      this.importsBox2.setOption(option2);
    },
    setid(row, value) {
      let aaid = ''
      if (value == 'Buyer') {
        aaid = row.buyerAaaid
      } else {
        aaid = row.supplierAaaid
      }
      let companyCountryEn = "";

      let routeData = this.$router.resolve({
        path:
          "/index/company/detals/" +
          aaid +
          "/background/snapshot?id3a=" +
          aaid +
          "&companyCountry=" +
          '' +
          "&companyCountryEn=" +
          companyCountryEn,
      });

      window.open(routeData.href, "_blank");

    },
  },
  computed: {},
  mounted() {
    this.getImportExportTransactionList()
    this.getImportExportALl()
    this.getPartPrivilege()
    // this.Centeroptionschange(1)
    // this.Centeroptionschange(2)

  },
};
</script>
<style scoped>
.operations-head {
  font-size: 2.142857142857143rem;
  /* font-family: "Arial Bold"; */
  color: #1a2332;
  text-align: left;
  font-style: normal;
  display: flex;
  justify-content: space-between;
  font-family: 'DMSans Bold';
}

.operations-box-head {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.el-dropdown-link {
  cursor: pointer;
  font-size: 1.4285714285714286rem;
  color: #1a2332;
  display: inline-block;
  width: 10.714285714285714rem;
  /* color: #409eff; */
}

.el-icon-arrow-down {
  position: absolute !important;
  right: 0.7142857142857143rem !important;
  top: 1.1428571428571428rem;
  font-size: 1.2857142857142858rem !important;
  color: #1290c9;
}

.operations-box-left /deep/ .el-table tr {
  background-color: rgba(245, 245, 245, 1);
  height: 6.428571428571429rem;
}

.operations-box-left /deep/ .el-table td.el-table__cell {
  border-bottom: 0.7142857142857143rem solid #ffff;
  font-size: 1.4285714285714286rem;
}

.operations-box-left /deep/ .el-table::before {
  content: "";
  position: absolute;
  background-color: #ffff;
  z-index: 1;
}

.operations-box-left {
  flex: 1;
  width: 0;
}

.operations-center-center {
  flex: 1;
  width: 0;
}

.table-content {
  /* height: 4rem !important;
  line-height: 4rem; */
}

.table-content-color {
  color: #1290c9;
}

.perations-button-active {
  background: #ffffff !important;
  box-shadow: 0rem 0.14285714285714285rem 0.2857142857142857rem 0rem rgba(0, 0, 0, 0.2);
  border-radius: 0.2857142857142857rem;
  color: #1290c9 !important;
}

.perations-button {
  display: inline-block;
  text-align: center;
  min-width: 7.857142857142857rem;
  height: 3.142857142857143rem;
  line-height: 3.142857142857143rem;
 padding: 0 !important;
  font-size: 1.4285714285714286rem;
  background-color: #e7eaed;
  color: #8497ab;
}

.perations-content-left {
  padding: 0.21428571428571427rem;
  background-color: #e7eaed;
  width: 16.428571428571427rem;
  display: flex;
  justify-content: space-between;
  border-radius: 0.28571429rem;
}

.operations-content-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f5f5;
  margin-bottom: 0.7142857142857143rem;
  height: 5.714285714285714rem;
  padding: 0rem 1.4285714285714286rem;
}

.operations-right-text {
  font-size: 1.4285714285714286rem;
  color: #1a2332;
}

.operations-right-head {
  display: flex;
  justify-content: space-between;
  border-radius: 0.2857142857142857rem;
}

.operations-box-right {
  width: 33%;
  max-width: 28.571428571428573rem;
  background: #ffffff;
  box-shadow: 0rem 0.14285714285714285rem 0.2857142857142857rem 0rem rgba(0, 0, 0, 0.12);
  padding: 1.4285714285714286rem;
  border-radius: 0.2857142857142857rem;
  padding-bottom: 0.7142857142857143rem !important;
}

.operations-center-center {
  /* width: 60%; */
  /* margin-right: 0.7142857142857143rem; */
}

.operations-center-select {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.7857142857142858rem;

}

.bottom-titletwo {
  font-size: 1.8571428571428572rem;
  color: #1A2332;
  font-family: 'DMSans Bold';
}

.operations-box-head-left {
  flex: 1;
  width: 60%;
  padding: 1.4285714285714286rem;
  padding-bottom: 0.7142857142857143rem !important;
  padding-right: 0.7142857142857143rem;
  background: #ffffff;
  margin-right: 0.7142857142857143rem;
  box-shadow: 0rem 0.14285714285714285rem 0.2857142857142857rem 0rem rgba(0, 0, 0, 0.12);
  border-radius: 0.2857142857142857rem;
}

.operations-box-center {
  padding: 1.4285714285714286rem;
  background: #ffffff;
  box-shadow: 0rem 0.14285714285714285rem 0.2857142857142857rem 0rem rgba(0, 0, 0, 0.12);
  border-radius: 0.2857142857142857rem;
}

.operations-box {
  background-color: #f5f5f5;
}

/deep/ .el-table .cell {
  font-size: 1.4285714285714286rem;
  color: #1a2332;
  line-height: 2.142857142857143rem;
  font-family: 'DMSans Bold';
  padding-left: 1.4285714285714286rem !important;
}

/deep/ .highlight-cell {
  background-color: #f2f5f9 !important;
}

/deep/ .el-table th.el-table__cell {
  background-color: #f2f5f9 !important;
}

.operations-box-bottom {
  padding: 0.7142857142857143rem;
}

.bottom-title {
  display: inline-block;
  font-family: 'DMSans Bold';
  font-weight: normal;
  font-size: 2.142857142857143rem;
  color: #1A2332;
  line-height: 1.9285714285714286rem;
}

.table_add {
  display: flex;
  justify-content: center;
}

.table_add>span {
  display: flex;
  align-items: center;
}

.operations-box-bottom /deep/ .el-table .cell {
  font-size: 1.4285714285714286rem;
  color: #1a2332;
  line-height: 1.6428571428571428rem;
  padding-left: 0.7142857142857143rem !important;
}

.operations-box-center-left {
  background: #F5F5F5FF;
  border-radius: 0.2857142857142857rem;
  padding: 1.4285714285714286rem;
  width: 49%;
  padding-bottom: 0rem;

}

.operations-box-center-headr {
  font-family: 'DMSans Bold';
  font-weight: normal;
  font-size: 2.142857142857143rem;
  color: #1290C9;
  margin-bottom: 1.4285714285714286rem;
}

.TopPorts {
  font-family: 'DMSans Bold';
  font-weight: normal;
  font-size: 1.4285714285714286rem;
  color: #1A2332;
  line-height: 1.6428571428571428rem;

}

.Byshipments {
  font-size: 1.1428571428571428rem;
  color: #8497AB;
  line-height: 1.2857142857142858rem;
  margin-top: 0.7142857142857143rem;
  margin-bottom: 1.4285714285714286rem;
  font-weight: normal;
}

.flex_center_between_box {
  display: flex;
  justify-content: space-between;
}

.line_step {
  margin-bottom: 2.142857142857143rem;
  font-size: 1.1428571428571428rem;
  color: #1290C9;
}

.Byshipments-table {
  height: 25rem;
}

.Byshipments-table .line_step:last-child {
  margin-bottom: 4.285714285714286rem;
}

.line_step .line_title {
  display: inline-block;
  width: 85%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1.2857142857142858rem;

  text-decoration-line: underline;
}

.blue_num {
  display: block;
  text-align: right;
  color: #1290c9;
}

.line_step /deep/.el-progress {
  margin-top: 0.7142857142857143rem;
}

.echartsimport {
  position: relative;
  top: -2rem;
}

.el-select .el-input__inner {
  font-size: 1.4285714285714286rem !important;
}

.callBack {
  font-size: 1.4285714285714286rem;
  color: #1290C9;
  line-height: 1.6428571428571428rem;
  text-align: right;
  font-style: normal;
  text-decoration-line: underline;
}

/deep/ .el-select {

  position: relative;
  z-index: 9999;
}

.operations-content /deep/ .el-table td:last-child .cell {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* edit */
.operations-content {
  height: 100%;
}

::v-deep .el-progress-bar__innerText {
  font-size: 1rem;
  color: #ffffff !important;
}
</style>